import React, { useState } from 'react'
import Picture from '../../Picture/'
import ButtonFeature from './button'
import FeatureImg from './featureImg'

import * as S from './styles'

import metaspeedImg from '../../../assets/images/metaspeed/metaspeed.png'
import metaspeedmobileImg from '../../../assets/images/metaspeed/metaspeed-mobile-features.png'

import feature1Img from '../../../assets/images/metaspeed/feature1.png'
import feature2Img from '../../../assets/images/metaspeed/feature2.png'
import feature3Img from '../../../assets/images/metaspeed/feature3.png'
import feature4Img from '../../../assets/images/metaspeed/feature4.png'

import feature1ImgMobile from '../../../assets/images/metaspeed/feature1-mobile.png'
import feature2ImgMobile from '../../../assets/images/metaspeed/feature2-mobile.png'
import feature3ImgMobile from '../../../assets/images/metaspeed/feature3-mobile.png'
import feature4ImgMobile from '../../../assets/images/metaspeed/feature4-mobile.png'

const Features = () => {
  const [showFeature, setShowFeature] = useState('')

  return (
    <S.WrapperFeatures id="features">
      <S.wrapperMetaspeed>
        <Picture
          xs={metaspeedmobileImg}
          md={metaspeedImg}
          lg={metaspeedImg}
          alt="Novo Gel-Cumulus 23"
        />
        <S.FeaturesDesktop>
          <ButtonFeature
            onClick={() => setShowFeature('feature1')}
            className="btn-feature1"
            type="button"
          >
            feature 1
          </ButtonFeature>
          <ButtonFeature
            onClick={() => setShowFeature('feature2')}
            className="btn-feature2"
            type="button"
          >
            feature 2
          </ButtonFeature>
          <ButtonFeature
            onClick={() => setShowFeature('feature3')}
            className="btn-feature3"
            type="button"
          >
            feature 3
          </ButtonFeature>
          <ButtonFeature
            onClick={() => setShowFeature('feature4')}
            className="btn-feature4"
            type="button"
          >
            feature 4
          </ButtonFeature>

          {showFeature === 'feature1' && (
            <FeatureImg
              className="img-feature1"
              src={feature1Img}
              alt="Nova lingueta e detalhes que agregam valor"
            />
          )}
          {showFeature === 'feature2' && (
            <FeatureImg
              className="img-feature2"
              src={feature2Img}
              alt="Colarinho macio facilitando o calce"
            />
          )}
          {showFeature === 'feature3' && (
            <FeatureImg
              className="img-feature3"
              src={feature3Img}
              alt="Design moderno e palmilha confortável"
            />
          )}
          {showFeature === 'feature4' && (
            <FeatureImg
              className="img-feature4"
              src={feature4Img}
              alt="Nova geometria e mais performance"
            />
          )}
        </S.FeaturesDesktop>
        <S.FeaturesMobile>
          <img
            src={feature1ImgMobile}
            alt="Nova lingueta e detalhes que agregam valor"
          />
          <img
            src={feature2ImgMobile}
            alt="Colarinho macio facilitando o calce"
          />
          <img
            src={feature3ImgMobile}
            alt="Design moderno e palmilha confortável"
          />
          <img
            src={feature4ImgMobile}
            alt="Nova geometria e mais performance"
          />
        </S.FeaturesMobile>
      </S.wrapperMetaspeed>
    </S.WrapperFeatures>
  )
}

export default Features
