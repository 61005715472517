import React from 'react'
export const DiagnoseAbout = () => {
  return (
    <hgroup>
      <h2>Descubra qual o modelo ideal para a sua corrida</h2>
      <p>
        Monitore sua cadência correndo 1 km em seu Pace de 5 km. Depois,
        monitore uma segunda corrida de 1km, aproximadamente, 30 segundos por
        quilômetro mais lenta do que seu Pace de corrida de 5 km. Por exemplo:
        se o seu Pace de corrida é de 4 minutos por quilômetro, corra uma vez
        neste ritmo e depois corra novamente a 4:30 minutos por quilômetro. Caso
        você não saiba ou tenha dúvidas sobre qual é o seu Pace, corra no ritmo
        mais rápido que puder sustentar por 5 km para a primeira tentativa e 30
        segundos mais devagar para a segunda tentativa.
      </p>
      <br />
      <b>
        Insira a cadência média rastreada no campo abaixo e selecione o botão
        calcular para determinar <br />
        qual o produto mais indicado para o seu tipo de corrida:
      </b>
    </hgroup>
  )
}
