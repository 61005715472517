import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const CardTecnologia = ({ title, tecnologias, isOrange = false }) => {
  return (
    <S.CardTecnologia>
      <S.CardTecnologiaHead isOrange={isOrange}>{title}:</S.CardTecnologiaHead>
      <S.CardTecnologiaBody>
        <ul>
          {tecnologias.map((tec, index) => (
            <S.CardItemList isOrange={isOrange} key={`${tec}-${index}`}>
              {tec}
            </S.CardItemList>
          ))}
        </ul>
      </S.CardTecnologiaBody>
    </S.CardTecnologia>
  )
}

CardTecnologia.propTypes = {
  title: PropTypes.string.isRequired,
  tecnologias: PropTypes.array.isRequired,
  indicacao: PropTypes.string,
  especificacoes: PropTypes.array,
  isOrange: PropTypes.bool,
}

export default CardTecnologia
