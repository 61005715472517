import React, { useEffect } from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import BannerTopo from '../../components/Metaspeed/banner-topo'
import CardTecnologia from '../../components/Metaspeed/Tecnologia'
import CardIndicacao from '../../components/Metaspeed/Indicacao'
import CardEspecificacoes from '../../components/Metaspeed/Especificacoes'
import Features from '../../components/Metaspeed/Features'
import FormNewsletter from '../../components/Newsletter'
import DiagnoseToolsAsics from '../../components/Metaspeed/diagnose-tool-asics'
import { ditoTrack } from '../../helpers/dito'

import {
  cumulus22Img,
  cumulus23Img,
  girlHoldingSneakerImg,
} from '../../images/metaspeed'

import * as S from './styles'
import { graphql } from 'gatsby'

const MetaSpeed = () => {
  useEffect(() => {
    ditoTrack('acessou-metaspeed')
  }, [])

  return (
    <Layout>
      <SEO title="Metaspeed" />

      <BannerTopo />

      <S.WrapperLancamento>
        <S.ItemLancamento>
          <S.ImgLancamento src={girlHoldingSneakerImg} alt="Asics metaspeed" />
        </S.ItemLancamento>
        <S.ItemLancamento>
          <S.TextWrapperLancamento>
            <h1>
              Está chegando o novo modelo inovador <br /> da ASICS com placa de
              carbono que traz <br /> alta performance e ainda mais velocidade{' '}
              <br />
              <strong>METASPEED™ EDGE</strong>
            </h1>
            <p>
              Projetado para ajudar a controlar seu ritmo em busca de mais
              velocidade e eficiência da passada, o modelo <br /> foi feito para
              corridas inovadoras.
            </p>
          </S.TextWrapperLancamento>
          <S.ButtonNewsLetter>
            <a
              href="https://www.asics.com.br/colecao/metaspeed?utm_source=asicslab&utm_medium=banner&utm_campaign=metaspeed-lab-banner"
              target="_blank"
              rel="noreferrer"
            >
              COMPRE AGORA
            </a>
          </S.ButtonNewsLetter>
        </S.ItemLancamento>
      </S.WrapperLancamento>

      <Features />

      <S.WrapperMetaSpeed>
        <S.TitleMetaSpeed>Confira os modelos da linha:</S.TitleMetaSpeed>
        <S.ContentMetaSpeed>
          <S.ItemMetaSpeed>
            <img src={cumulus23Img} alt="Asics metaspeed sky" />
            <S.ItemMetaSpeedWrap>
              <h2>METASPEED™ SKY</h2>
              <CardTecnologia
                title="cabedal"
                tecnologias={['Engineered Mesh', 'Poliéster 100% reciclado']}
              />
              <CardTecnologia
                title="entressola"
                tecnologias={[
                  'FLYTEFOAM Blast Turbo™ na entressola;',
                  'Tecnologia ASICSGRIP™ na sola',
                  'Placa de Carbono em toda a extensão da sola',
                ]}
              />
              <CardIndicacao article="Para corredores com estilo stride. O formato de entressola mais alta permite uma melhor resposta entre as passadas, proporcionando mais velocidade com passadas mais alongadas. Ideal para quem busca alta performance, responsividade e maior impulsão durante a corrida." />
              <CardEspecificacoes
                especificacoes={[
                  'Drop = 5mm (M) ',
                  'Drop = 5mm (F) ',
                  'Tamanho altura traseira: 33 (M) e 31 (F)',
                  'Tamanho altura dianteira: 28 (M) e 26 (F)',
                  'Peso = 210g (M)',
                  'Peso = 180g (F)',
                ]}
              />
              <S.ButtonNewsLetter>
                <a
                  href="https://www.asics.com.br/colecao/metaspeed?utm_source=asicslab&utm_medium=banner&utm_campaign=metaspeed-lab-banner"
                  target="_blank"
                  rel="noreferrer"
                >
                  COMPRE AGORA
                </a>
              </S.ButtonNewsLetter>
            </S.ItemMetaSpeedWrap>
          </S.ItemMetaSpeed>
          <S.ItemMetaSpeed>
            <img src={cumulus22Img} alt="Metaspeed Edge" />
            <S.ItemMetaSpeedWrap className="metaspeed-edge">
              <h2>METASPEED™ EDGE</h2>
              <CardTecnologia
                title="cabedal"
                tecnologias={['Engineered Mesh', 'Poliéster 100% reciclado']}
                isOrange
              />
              <CardTecnologia
                title="entressola"
                tecnologias={[
                  'FLYTEFOAM Blast Turbo™ na entressola;',
                  'Tecnologia ASICSGRIP™ na sola',
                  'Placa de Carbono em toda a extensão da sola',
                ]}
                isOrange
              />
              <CardIndicacao article="Para corredores com estilo cadência. Com estrutura de entressola mais baixa e leve, é o modelo ideal para transições mais eficientes e melhor controle de ritmo durante as passadas e melhor performance, com alta responsividade e maior impulsão durante a corrida." />
              <CardEspecificacoes
                especificacoes={[
                  'Drop = 8mm (M) ',
                  'Drop = 8mm (F) ',
                  'Tamanho altura traseira: 29 (M) e 28 (F) ',
                  'Tamanho altura dianteira: 21 (M) e 20 (F) ',
                  'Peso = 188g (M) ',
                  'Peso = 160g (F)',
                ]}
              />
              <S.ButtonNewsLetter>
                <a
                  href="https://www.asics.com.br/colecao/metaspeed?utm_source=asicslab&utm_medium=banner&utm_campaign=metaspeed-lab-banner"
                  target="_blank"
                  rel="noreferrer"
                >
                  COMPRE AGORA
                </a>
              </S.ButtonNewsLetter>
            </S.ItemMetaSpeedWrap>
          </S.ItemMetaSpeed>
        </S.ContentMetaSpeed>
      </S.WrapperMetaSpeed>
      <DiagnoseToolsAsics />
      <S.WrapperNewsletter id="newsLetterWrap">
        <S.ContentNewsletter>
          <h2>CADASTRE SEU E-MAIL PARA RECEBER OFERTAS E NOVIDADES</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP origem="lp-lab-metaspeed" />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default MetaSpeed

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
