import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const CardEspecificacoes = ({ especificacoes }) => {
  return (
    <S.CardEspecificacoes>
      <S.CardEspecificacoesHead>Especificações:</S.CardEspecificacoesHead>
      <S.CardEspecificacoesBody>
        <ul>
          {especificacoes.map((esp, index) => (
            <S.CardItemList key={`${esp}-${index}`}>{esp}</S.CardItemList>
          ))}
        </ul>
      </S.CardEspecificacoesBody>
    </S.CardEspecificacoes>
  )
}

CardEspecificacoes.propTypes = {
  especificacoes: PropTypes.array.isRequired,
}

export default CardEspecificacoes
