import styled from 'styled-components'

export const CardTecnologia = styled.article`
  display: inline-block;
  width: 100%;
  margin: 0 0 30px 0;

  @media (min-width: 992px) {
    width: 450px;
  }
`

export const CardTecnologiaHead = styled.header`
  //background-color: ${({ isOrange }) => (isOrange ? '#fa8452' : '#0485c8')};
  border-radius: 99999px;
  color: #485cc7;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 3px 15px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

export const CardTecnologiaBody = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
`

export const CardItemList = styled.li`
  color: #9697a9;
  font-size: 16px;
  list-style-type: none;
  padding-left: 20px;
  position: relative;
  font-style: italic;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    content: '';
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    display: block;
    position: absolute;
    top: 2px;
    left: 0;
    background-color: ${({ isOrange }) => (isOrange ? '#fa8452' : '#0485c8')};
    width: 12px;
    height: 12px;
  }
`
