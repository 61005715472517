import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const CardIndicacao = ({ article }) => {
  return (
    <S.CardIndicacao>
      <S.CardIndicacaoHead>Indicado para quais atletas?</S.CardIndicacaoHead>
      <S.CardIndicacaoBody>
        <p>{article}</p>
      </S.CardIndicacaoBody>
    </S.CardIndicacao>
  )
}

CardIndicacao.propTypes = {
  article: PropTypes.string.isRequired,
}

export default CardIndicacao
