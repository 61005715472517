import styled from 'styled-components'

import bgFeatures from '../../../assets/images/metaspeed/bg-features.jpg'
import bgFeaturesMobile from '../../../assets/images/metaspeed/bg-features-mobile.jpg'

export const WrapperFeatures = styled.section`
  align-items: center;
  background-image: url(${bgFeatures});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  //height: 540px;
  justify-content: center;
  width: 100%;
  margin-top: 40px;

  @media (max-width: 991px) {
    background-image: url(${bgFeaturesMobile});
    height: auto;
    padding: 50px 0 30px 0;
  }

  @media (min-width: 1024px) {
    padding: 100px 0 30px 0;
  }

  @media (min-width: 1700px) {
    height: 675px;
  }
`

export const FeaturesDesktop = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export const wrapperMetaspeed = styled.div`
  position: relative;
  width: 1000px;

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (min-width: 1700px) {
    width: 930px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 750px;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (min-width: 1700px) {
      width: auto;
    }
  }
`

export const ButtonFeature = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 50%;
  text-indent: -9999px;
  top: 50%;
  width: 20px;
  z-index: 2;

  &.btn-feature1 {
    transform: translate(101px, -63px);

    @media (min-width: 1700px) {
      transform: translate(123px, -75px);
    }
  }

  &.btn-feature2 {
    transform: translate(-330px, 10px);

    @media (min-width: 1700px) {
      transform: translate(-413px, 16px);
    }
  }

  &.btn-feature3 {
    transform: translate(-214px, 99px);

    @media (min-width: 1700px) {
      transform: translate(-269px, 127px);
    }
  }

  &.btn-feature4 {
    transform: translate(137px, 100px);

    @media (min-width: 1700px) {
      transform: translate(166px, 130px);
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
`

export const FeatureImg = styled.img`
  left: 50%;
  position: absolute;
  top: 50%;

  &.img-feature1 {
    transform: translate(88px, -344px);
    width: 485px;

    @media (min-width: 1700px) {
      transform: translate(112px, -339px);
      width: auto;
    }
  }

  &.img-feature2 {
    transform: translate(-462px, -278px);
    width: 388px;

    @media (min-width: 1700px) {
      transform: translate(-542px, -266px);
      width: auto;
    }
  }

  &.img-feature3 {
    transform: translate(-335px, -165px);
    width: 387px;

    @media (min-width: 1700px) {
      transform: translate(-389px, -134px);
      width: auto;
    }
  }

  &.img-feature4 {
    transform: translate(126px, -153px);
    width: 406px;

    @media (min-width: 1700px) {
      transform: translate(158px, -123px);
      width: auto;
    }
  }
`

export const FeaturesMobile = styled.div`
  margin: 30px 0;
  padding: 0 20px;
  width: 100%;

  img {
    margin: 15px 0;
    width: 100%;
  }

  @media (min-width: 991px) {
    display: none;
  }
`
