import styled from 'styled-components'

export const CardEspecificacoes = styled.article`
  margin: 0 0 30px 0;
  display: inline-block;
  width: 100%;

  @media (min-width: 992px) {
    width: 450px;
  }
`

export const CardEspecificacoesHead = styled.header`
  color: #424242 !important;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 10px 15px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

export const CardEspecificacoesBody = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  font-style: italic;
`

export const CardItemList = styled.li`
  color: #424242;
  font-size: 16px;
  list-style-type: none;
  position: relative;
  font-style: italic;
`
