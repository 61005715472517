import styled from 'styled-components'

export const CardIndicacao = styled.article`
  margin: 0 0 30px 0;

  @media (min-width: 992px) {
    width: 450px;
  }
`

export const CardIndicacaoHead = styled.header`
  color: #485cc7;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 10px 15px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const CardIndicacaoBody = styled.div`
  align-items: center;
  //background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  width: 100%;
  font-style: italic;
`
