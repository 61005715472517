import styled from 'styled-components'

export const WrapperLancamento = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin-top: 14px;
  width: 100%;
  margin: 0 auto;
  margin-top: -70px;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 0;
  }

  @media (min-width: 992px) {
    flex-flow: row;
  }

  @media (min-width: 1024px) and (min-width: 1366px) {
    max-width: 1000px;
  }

  @media (min-width: 1700px) {
    max-width: 1120px;
  }
`

export const ItemLancamento = styled.section`
  flex: 1;
  //margin-right:30px;
`

export const ImgLancamento = styled.img`
  position: relative;
  top: 6px;
  width: 500px;
  margin-right: 40px;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }

  @media (min-width: 1024px) and (max-width: 1366px) {
    width: 450px;
  }

  @media (min-width: 1700px) {
    width: auto;
  }
`

export const TextWrapperLancamento = styled.div`
  //align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 490px;

  @media (max-width: 991px) {
    padding: 30px;
    text-align: center;
    width: 100%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 15px;
    width: 100%;
  }

  h1 {
    color: #b9411d;
    font-size: 28px;
    font-weight: 100;
    font-style: italic;
    margin-bottom: 20px;
    line-height: 36px;

    @media (max-width: 767px) {
      font-size: 20px;
      br {
        display: none;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 25px;
    }
  }

  p {
    color: #9697a9;
    font-size: 24px;
    font-style: italic;

    @media (max-width: 767px) {
      font-size: 19px;
    }

    @media (min-width: 990px) and (max-width: 1366px) {
      font-size: 18px;
      width: 420px;
    }

    @media (min-width: 1700px) {
      width: 420px;
    }
  }
`

export const WrapperMetaSpeed = styled.section`
  min-height: 300px;
  padding: 70px 0 30px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 35px 15px;
  }
`

export const TitleMetaSpeed = styled.h1`
  color: #b9411d;
  font-family: 'ASICSFont3.0-Italic';
  font-size: 45px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;

  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    margin-bottom: 70px;
  }
`

export const ContentMetaSpeed = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;

  @media (min-width: 992px) {
    flex-flow: row;
  }

  @media (min-width: 1024px) {
    max-width: 1040px;
  }
`

export const ItemMetaSpeed = styled.div`
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 50px;
    text-align: center;
  }

  h2 {
    color: #485cc7;
    font-size: 34px;
    font-family: 'ASICSFont3.0-BoldItalic';
    margin: 15px 0;
    text-align: center;
    text-transform: uppercase;
    @media (min-width: 1400px) {
      font-size: 43px;
    }
  }

  img {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`

export const ItemMetaSpeedWrap = styled.div`
  background: rgb(204, 244, 255);
  background: linear-gradient(
    180deg,
    rgba(204, 244, 255, 1) 60%,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    margin-top: -50px;
    padding: 60px 0 0 0;
  }
  &.metaspeed-edge {
    background: rgb(253, 239, 225);
    background: linear-gradient(
      180deg,
      rgba(253, 239, 225, 1) 60%,
      rgba(255, 255, 255, 1) 100%,
      rgba(255, 255, 255, 1) 100%
    );
    @media (min-width: 992px) {
      margin-top: -41px;
    }

    h2 {
      color: #b9411d;
    }
    article {
      header {
        color: #b9411d;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`

export const WrapperComprar = styled.section`
  padding: 50px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 50px 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 50px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 50px;
  }
`

export const TitleComprar = styled.h2`
  color: #fa8452;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 28px;
  margin-bottom: 5px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`

export const TextComprar = styled.p`
  color: #9697a9;
  font-size: 18px;
  font-style: italic;
`

export const ContentParaQuem = styled.div`
  margin: 0 auto 40px auto;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 767px) {
    text-align: center;
  }
`

export const ContentEspecificacoes = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const PrecoModelo = styled.span`
  color: #0485c8;
  display: block;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 60px;

  @media (max-width: 767px) {
    margin-top: 30px;
    text-align: center;
  }
`

export const ButtonNewsLetter = styled.div`
  a {
    background-color: #001e62;
    border-radius: 99999px;
    color: #fff;
    display: block;
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 17px;
    margin: 25px 0;
    padding: 10px 15px 7px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 360px;
    cursor: pointer;
    letter-spacing: 1px;
    &:hover {
      background-color: #08448e;
    }

    @media (max-width: 767px) {
      width: 330px;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 40px;
      font-size: 16px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 30px;
      margin: 0 auto;
    }
  }
`

export const WrapperNewsletter = styled.section`
  padding: 70px 0 50px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 15px 30px 15px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 28px;
    margin-bottom: 5px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  img {
    position: absolute;
    right: 90px;
    top: 60px;
    width: 400px;

    @media (max-width: 991px) {
      display: none;
    }
  }
`

export const FormNewsletter = styled.div`
  margin-top: 30px;
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
