import React from 'react'
import PropTypes from 'prop-types'

export const DiagnoseInputs = ({
  racePace,
  run2,
  handleChangeRacePace,
  handleChangeRun2,
  handdleDiagnoseTool,
  isDisabled,
}) => {
  return (
    <div className="diagnose-tool-inputs">
      <div className="average-cadence race-pace">
        <label>
          Cadência média do Pace
          <input
            type="number"
            name="race-pace"
            onChange={(event) => handleChangeRacePace(event)}
            value={racePace}
          />
          <span className="unit">Ciclo/min</span>
        </label>
      </div>
      <div className="average-cadence run-2">
        <label>
          Cadência média do Pace + 30
          <input
            type="number"
            name="run-2"
            onChange={(event) => handleChangeRun2(event)}
            value={run2}
          />
          <span className="unit">Ciclo/min</span>
        </label>
      </div>
      <div className="calculate-button-wrapper">
        <button
          disabled={isDisabled}
          className="button"
          onClick={handdleDiagnoseTool}
        >
          Calcular
        </button>
      </div>
    </div>
  )
}

DiagnoseInputs.propTypes = {
  racePace: PropTypes.string.isRequired,
  run2: PropTypes.string.isRequired,
  handleChangeRacePace: PropTypes.func.isRequired,
  handleChangeRun2: PropTypes.func.isRequired,
  handdleDiagnoseTool: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}
