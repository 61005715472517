import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1000px;
  margin: auto;
  @media (min-width: 1024px) {
    position: relative;
  }
`

export const BannerTopo = styled.div`
  position: relative;
`
export const DescriptionWrap = styled.div`
  position: absolute;
  top: 110px;
  left: 0;
  z-index: 1;
  font-family: 'ASICSFont3.0-Regular', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    color: #fff;
    font-size: 42px;
    line-height: 57px;
    letter-spacing: 3px;
    @media (min-width: 1024px) and (max-width: 1366px) {
      font-size: 35px;
      line-height: 45px;
    }
  }

  h1 {
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 82px;
    color: #fff;
    letter-spacing: -0.5px;
  }

  a {
    background: #fff;
    padding: 7px 30px 5px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #b9411d;
    text-align: center;
    cursor: pointer;
    font-family: 'ASICSFont3.0-Italic';
    font-weight: bold;
    letter-spacing: 2px;
    &:hover {
      background: #b9411d;
      color: #fff;
    }
  }

  @media (max-width: 767px) {
    top: auto;
    left: 20px;
    bottom: 40px;
    p {
      font-size: 23px;
      line-height: normal;
    }
    h1 {
      font-size: 44px;
    }

    a {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    top: auto;
    left: 40px;
    bottom: 120px;
  }

  @media (min-width: 990px) and (max-width: 1024px) {
    left: 20px;
    p {
      font-size: 30px;
      line-height: 45px;
    }

    h1 {
      font-size: 45px;
    }
  }
`
