import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'

export const DiagnoseOutput = ({ title, description, img, classe }) => {
  return (
    <div className="diagnose-tool-output meta-speed-edge">
      <div className="diagnose-tool-output-content">
        <hgroup>
          <h3>Seu par ideal é</h3>
          <p className={`title-product ${classe}`}>{title}</p>
          <p>{description}</p>
        </hgroup>
        <div className="shop-links">
          <a
            href="https://www.asics.com.br/colecao/metaspeed?utm_source=asicslab&utm_medium=banner&utm_campaign=metaspeed-lab-banner"
            target="_blank"
            rel="noreferrer"
          >
            COMPRE AGORA
          </a>
        </div>
      </div>
      <figure className="diagnose-tool-output-img">
        <img src={img} alt={description} />
      </figure>
    </div>
  )
}

DiagnoseOutput.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  isOrange: PropTypes.bool,
}
