import React from 'react'
import { useState, useEffect } from 'react'
import { DiagnoseAbout } from './components/DiagnoseAbout/'
import { DiagnoseInputs } from './components/DiagnoseInputs/'
import { DiagnoseOutput } from './components/DiagnoseOutput/'
import metaskydiagnose from '../../../assets/images/metaspeed/meta-speed-sky-diagnose-tools.png'
import metaedgediagnose from '../../../assets/images/metaspeed/meta-speed-edge-diagnose-tools.png'

import './styles/style.css'

const DiagnoseToolsAsics = () => {
  const [racePace, setRacePace] = useState('')
  const [run2, setRun2] = useState('')

  const [showEdge, setShowEdge] = useState(false)
  const [showSky, setShowSky] = useState(false)

  const [isDisabled, setIsDisabled] = useState(true)

  function handdleDiagnoseTool() {
    if (parseInt(racePace) > 190 || parseInt(run2) > 190) {
      setShowSky(false)
      setShowEdge(true)
      return
    }

    if (Math.abs(parseInt(racePace) - parseInt(run2)) >= 6) {
      setShowSky(false)
      setShowEdge(true)
      return
    }

    setShowEdge(false)
    setShowSky(true)
  }

  function handleChangeRacePace(event) {
    setRacePace(event.target.value)
  }

  function handleChangeRun2(event) {
    setRun2(event.target.value)
  }

  useEffect(() => {
    if (racePace !== '' && run2 !== '') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [racePace, run2])

  return (
    <main>
      <div className="container-instance" id="diagnose">
        <div className="container-wrapper">
          <div className="container-diagnose-tool">
            <DiagnoseAbout />
            <DiagnoseInputs
              racePace={racePace}
              run2={run2}
              handleChangeRacePace={handleChangeRacePace}
              handleChangeRun2={handleChangeRun2}
              handdleDiagnoseTool={handdleDiagnoseTool}
              isDisabled={isDisabled}
            />
            {showEdge && (
              <DiagnoseOutput
                title="METASPEED™ EDGE:"
                description="Mantém a cadência mesmo com o aumento da velocidade da corrida."
                menLink="#/"
                womenLink="#/"
                img={metaedgediagnose}
                classe="isOrange"
              />
            )}
            {showSky && (
              <DiagnoseOutput
                title="METASPEED™ Sky:"
                description="Passadas que aumentam de acordo com a velocidade da corrida."
                menLink="#/"
                womenLink="#/"
                img={metaskydiagnose}
                classe=""
              />
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default DiagnoseToolsAsics
